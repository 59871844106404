<template>
  <v-container>
    <v-form
      ref="chargeAmpsForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="handleSubmit"
    >
      <v-text-field
        autocomplete="off"
        v-model="payload.email"
        :label="$t('user.fields.email')"
        :rules="rules"
      />
      <v-text-field
        autocomplete="off"
        v-model="payload.password"
        type="password"
        :label="$t('user.fields.password')"
        :rules="rules"
      />
      <v-text-field
        autocomplete="off"
        v-model="payload.apiKey"
        :label="$t('integrations.chargeAmps.fields.apiKey')"
        :rules="rules"
        :hint="$t('integrations.chargeAmps.apiKeyDescription')"
        persistent-hint
      />
      <v-text-field
        autocomplete="off"
        v-model="payload.organizationId"
        :label="$t('integrations.chargeAmps.fields.organizationId')"
        :hint="$t('integrations.chargeAmps.organizationIdDescription')"
        :rules="rules"
        persistent-hint
      />
      <v-switch
        v-model="payload.useRfid"
        :label="$t('integrations.chargeAmps.fields.useRfid')"
        :hint="$t('integrations.chargeAmps.useRfidDescription')"
        persistent-hint
        class="mb-4"
      />
      <v-btn block color="primary" type="submit">
        {{ $t("common.add") }}
      </v-btn>
    </v-form>

    <v-data-table
      :headers="[
        { text: $t('user.fields.email'), value: 'email' },
        {
          text: $t('integrations.chargeAmps.fields.organizationId'),
          value: 'organizationId',
        },
        {
          text: $t('integrations.chargeAmps.fields.apiKey'),
          value: 'apiKey',
        },
        {
          text: $t('integrations.chargeAmps.fields.useRfid'),
          value: 'useRfid',
        },

        { text: '', value: 'actions', sortable: false },
      ]"
      :items="chargeAmps"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.useRfid="{ item }">
        <v-icon>{{ item.useRfid ? "mdi-check" : "mdi-close" }}</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          @click="handleRemove(item.integrationSettingId)"
          class="mr-2"
          color="red"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import SettingsRepository from "@/api/repositories/settingRepository";
import { IntegrationTypes, IntegrationValueTypes } from "@/_helpers/CsharpEnum";

const integrationType = IntegrationTypes.CHARGE_AMPS;
export default {
  name: "IntegrationChargeAmps",
  props: {
    integrations: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update"],

  data() {
    return {
      valid: false,
      rules: [(v) => !!v || this.$t("validation.requiredSingle")],
      localIntegrations: [],
      payload: {
        email: "",
        password: "",
        apiKey: "",
        organizationId: "",
        useRfid: false,
      },
    };
  },

  computed: {
    chargeAmps() {
      return this.localIntegrations
        .filter((integration) => integration.type === integrationType)
        .map((integration) => {
          let json = JSON.parse(integration.value);
          return {
            integrationSettingId: integration.integrationSettingId,
            email: json.email,
            organizationId: json.organizationId,
            apiKey: json.apiKey,
            useRfid: json.useRfid,
          };
        });
    },
  },

  methods: {
    async handleRemove(integrationSettingId) {
      try {
        await SettingsRepository.removeIntegrationSetting(integrationSettingId);
      } catch (error) {
        console.error(error);
      }

      this.$emit("update");
    },

    async handleSubmit() {
      if (!this.$refs.chargeAmpsForm.validate()) return;

      // Construct payload
      let setting = {
        type: integrationType,
        valueType: IntegrationValueTypes.JSON,
        value: JSON.stringify(this.payload),
        key: "CHARGEAMPS",
      };

      try {
        await SettingsRepository.createIntegrationSetting(setting);
      } catch (error) {
        console.error(error);
      }

      this.$emit("update");
    },
  },

  created() {
    this.localIntegrations = this.integrations;
  },

  watch: {
    integrations: {
      handler(value) {
        this.localIntegrations = value;
      },
      deep: true,
    },
  },
};
</script>
